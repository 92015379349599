/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        mc4wp.forms.on('subscribed', function(form) {
          // Google Analytics by Yoast
          ga('send', 'event', 'Forms', 'Submission', 'MailChimp for WP Sign-Up');
        });

        $('.phone').on('click', function() {
          // Google Analytics by Yoast
          ga('send', 'event', 'UX', 'Click', 'Phone Number');
        });

        $('.find-us').on('click', function() {
          // Google Analytics by Yoast
          ga('send', 'event', 'UX', 'Click', 'Find Us');
        });

        $('.dropdown-toggle').on("touchstart", function(e){
          if( $(this).parents('.menu-dropdowncart').length > 0 ) {
            window.location = $(this).attr('href');
            e.stopPropagation();
            e.preventDefault();
          } else {
            $(this).next('ul').toggle();
            e.stopPropagation();
            e.preventDefault();
          }
        });

        $('.gallery-icon, .category-block').on('click', function() {
          window.location = $(this).find('a').attr('href');
        });

        $('.schematics.excerpt').matchHeight({
          target: $('.schematics.excerpt').parent(),
          property: 'min-height',
          byRow: false
        });

        $('.guides.excerpt').matchHeight({
          target: $('.guides.excerpt').parent(),
          property: 'min-height',
          byRow: false
        });

        $('.category-block, .schematics.excerpt .entry-title, .guides.excerpt .entry-title').matchHeight({
          property: 'min-height'
        });

        var add_to_cart_button,
            add_to_cart_product_id,
            add_to_cart_quantity;
            add_to_cart_quantity;
        $( document.body ).on( 'adding_to_cart', function(e, button, data) {
          add_to_cart_button = button;
          add_to_cart_product_id = data.product_id;
          add_to_cart_quantity = data.quantity;

          product_el = $('ul.products li.post-' + add_to_cart_product_id);
          product_el.addClass('adding-to-cart-active');
        });
        $( document.body ).on( 'added_to_cart', function(e, fragments, cart_hash, button) {
          if( button === add_to_cart_button ) {
            product_el.addClass('added-to-cart-success');

          }
        });

        if( $('.featured-products-carousel .products').length > 0 ) {
          $('.featured-products-carousel .products').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });
        }

        $.each($('.sidebar .widget'), function() {
          if( $(this).hasClass('woocommerce widget_layered_nav') || $(this).hasClass('woocommerce widget_price_filter') ) {
            var widget_id = $(this).attr('id');
            var widget_title = $(this).find('.widget-title').text();
            var widget_title_html = '<a data-toggle="collapse" href="#collapse-' + widget_id + '" aria-expanded="true" aria-controls="collapse-' + widget_id + '">' + widget_title + '</a>';
            var widget_content = $(this).find('.widget-title').next();

            $(this).find('.widget-title').attr('style', 'padding-top: 0; padding-bottom: 0');

            $(this).find('.widget-title').html(widget_title_html);
            widget_content.wrap('<div class="collapse in" id="collapse-' + widget_id + '"></div>');
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // My Account
    'my_account': {
      init: function() {
        $('.u-columns form').matchHeight();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_product': {
      init: function() {
        $('.product .images .thumbnails a').magnificPopup({
          type: 'image',
          gallery: {
            enabled: true
          }
        });

        $( ".variations_form" ).on( "show_variation", function ( event, variation ) {
          var data = {
            'action': 'ajax_get_product_price',
            'variation_id': variation.variation_id
          };

          $.ajax({
            type: "post",
            url: ajax_object.ajaxurl,
            dataType: "JSON",
            data: data,
            beforeSend: function() {
              $('.entry-summary .price').html('Loading...').addClass('loading');
            },
            success: function(response) {
              var price = response.price_html;
              $('.entry-summary .price').html(price).removeClass('loading');
            }
          });

        } );

        var default_price = $('.entry-summary > div .price').html();
        $( ".variations_form" ).on( "woocommerce_variation_select_change", function () {
          $('.entry-summary .price').html(default_price);
        } );
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
